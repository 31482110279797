import React from "react"

import Layout from "../components/Layout"
import TimetableNotice from "../components/Content/TimetableNotice"
import Heading from "../components/Layout/heading"

import SEO from "../components/SEO"

import stuartsFleet from "../images/fleet/stuarts-fleet.jpg"

const Fleet = (props) => (
  <Layout location={props.location}>
    <SEO
      title="Our Fleet"
      description="Our coaches range in seating capacity from 20 to 57, all fitted with
          seatbelts, air conditioning and tinted windows for added comfort and
          safety."
    />
    <TimetableNotice />
    <Heading title="Our Fleet" />
    <div className="row fleet-container">
      <div className="col-12 col-md-6">
        <p>
          Stuarts Coaches has a fleet of 12 Busses &amp; Coaches. 10 of which
          are Australian built
        </p>
        <p>
          Our coaches range in seating capacity from 20 to 57, all fitted with
          seatbelts, air conditioning and tinted windows for added comfort and
          safety
        </p>
        <p>
          Our long distance touring coaches are also fitted with reclining
          seats, curtains, restrooms and DVD players for your relaxation
        </p>
      </div>
      <div className="col-12 col-md-6">
        <img src={stuartsFleet} alt="Stuarts Fleet" className="fleet-image" />
      </div>
    </div>
  </Layout>
)

export default Fleet
